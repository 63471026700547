import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Alert,
  Card,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/system";
import useStyles from "../../registerStyles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../../assets/profile/avatar.svg";

import { fetchProfileDetails } from "../../../store/slices/profile/profileDetailsSlice";

import { clearProfileUpdateState } from "../../../store/slices/profile/profileUpdateSlice";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import EditProfile from "./EditProfile";

import ReactSwipeButton from "../../../components/SwipeButton/Button";
import RedeemModal from "./RedeemModal";

import { clearRedpState } from "../../../store/slices/redeemPointsSlice";

import { profileUpdate } from "../../../store/slices/profile/profileUpdateSlice";

import Swal from "sweetalert2";

import S3 from "react-aws-s3";

import { Buffer } from "buffer";

// @ts-ignore
window.Buffer = Buffer;

const GridContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginTop: "6%",
  marginBottom: "10%",
  "@media (max-width: 600px)": {
    marginTop: "30%",
    marginBottom: "20%",
  },
});

const GridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_BUCKET_REGION,
    dirName: 'helvit-india',
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const { redpSuccess } = useSelector((state) => state.redeemPointsSlice);

  const [name, setName] = React.useState("");
  const [toggleRedeem, setToggleRedeem] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [profile_picture, setProfilePicture] = React.useState("");

  const [editProfile, setEditProfile] = React.useState(false);
  const [editDealer, setEditDealer] = React.useState(false);

  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("helvit-prod-user"))?.id
  );

  const { profileDetails } = useSelector((state) => state.profileDetailsSlice);

  const { pupSuccess, pupError } = useSelector(
    (state) => state.profileUpdateSlice
  );

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, [user, pupSuccess]);

  useEffect(() => {
    setName(profileDetails?.name);
  }, [profileDetails]);

  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const logout = (event) => {
    event.preventDefault();
    localStorage.removeItem("helvit-prod-token");
    localStorage.removeItem("verified");
    localStorage.removeItem("helvit-prod-user");
    history.push("/auth/login");
  };

  useEffect(() => {
    if (pupSuccess) {
      setUploading(false);
      setTimeout(() => {
        dispatch(clearProfileUpdateState());
      }, [3000]);
    } else if (pupError) {
      setTimeout(() => {
        dispatch(clearProfileUpdateState());
        dispatch(fetchProfileDetails());
      }, [3000]);
    }
  }, [pupSuccess, pupError]);

  const handleProfileEdit = () => {
    dispatch(clearProfileUpdateState());
    setEditProfile(true);
  };

  useEffect(() => {
    if (redpSuccess) {
      Swal.fire({
        title: "Success!",
        text: "Points redeemed successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#00435E",
      });
      dispatch(clearRedpState());
    }
  }, [redpSuccess]);

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    else if (file.size > 1000000) {
      Swal.fire({
        title: "Error!",
        text: "File size should be less than 1MB",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#00435E",
      });
      return;
    } else if (!file.type.includes("image")) {
      Swal.fire({
        title: "Error!",
        text: "File should be an image",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#00435E",
      });
      return;
    } else {
      setUploading(true);
      ReactS3Client.uploadFile(file, file.name)
        .then((data) => {
          setProfilePicture(data.location);
          let payload = {
            profile_picture: data.location,
          };

          dispatch(profileUpdate({ payload }));
          console.log(data);
        })
        .catch((err) => {
          setUploading(false);
          console.error(err);
        });
    }
  };

  return (
    <>
      {!editProfile && !editDealer && (
        <Container sx={{ zIndex: 0, width: "100%" }}>
          <GridContainer container spacing={3}>
            {(pupSuccess || pupError) && (
              <GridItem item>
                <Slide
                  direction="down"
                  in={pupSuccess || pupError}
                  mountOnEnter
                  unmountOnExit
                >
                  <Alert
                    style={{ width: "100%" }}
                    severity={`${
                      pupSuccess ? "success" : pupError ? "error" : ""
                    }`}
                  >
                    {pupSuccess
                      ? "Profile updated successfully"
                      : pupError
                      ? "Something went wrong"
                      : ""}
                  </Alert>
                </Slide>
              </GridItem>
            )}
            <GridItem item>
              <Grid item xs={12} lg={4}>
                <label className={classes.profilepic}>
                  {!uploading && (
                    <img
                      src={
                        profileDetails?.profile_picture?.length > 0
                          ? profileDetails.profile_picture
                          : avatar
                      }
                      className={classes.avatar}
                      alt="avatar"
                    />
                  )}
                  {uploading && (
                    <div className={classes.avatar}>
                      <lottie-player
                        src="https://assets6.lottiefiles.com/packages/lf20_z7DhMX.json"
                        background="transparent"
                        speed="1"
                        style={{ width: "150px", height: "150px" }}
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                  )}
                  <div className={classes.avatarText}>Change it</div>
                  <input
                    type="file"
                    onChange={handleProfileImageChange}
                    style={{ display: "none" }}
                  />
                </label>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography className={classes.secondaryText} marginTop={2}>
                  {name?.charAt(0)?.toUpperCase() + name?.slice(1)}
                </Typography>
              </Grid>
            </GridItem>
            <GridItem item>
              <Card
                sx={{
                  width: { xs: "100%", lg: "36%" },
                  boxShadow: "0px 0px 4px rgba(23, 74, 119, 0.25)",
                  borderRadius: "8px",
                  padding: "1rem",
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <Button
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "none",
                        color: "#00435E",
                      }}
                      onClick={handleProfileEdit}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Edit Profile Details
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12} lg={12}>
                    <Button
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "none",
                        color: "#00435E",
                      }}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Edit Dealer Details
                    </Button>
                  </Grid> */}
                </Grid>
              </Card>
            </GridItem>
            <GridItem item xs={12} lg={5} md={4}>
              <ReactSwipeButton
                color="#01435F"
                text="SLIDE TO REDEEM"
                text_unlocked="SUCCESS"
                toggleRedeem={toggleRedeem}
                onSuccess={() => setToggleRedeem(true)}
              />
            </GridItem>
            <GridItem item xs={12} lg={5} md={4}>
              <Button onClick={logout} className={classes.secondaryButton}>
                Logout
              </Button>
            </GridItem>
          </GridContainer>
        </Container>
      )}
      {editProfile && <EditProfile setEditProfile={setEditProfile} />}
      {RedeemModal && (
        <RedeemModal toggle={toggleRedeem} setToggle={setToggleRedeem} />
      )}
    </>
  );
};

export default Profile;
