import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ScanIcon from "@mui/icons-material/QrCodeScanner";
import ProfileIcon from "@mui/icons-material/Person3";

import Dashboard from "../pages/Home/Dashboard/Index";
import Scan from "../pages/Home/Scan/Index";
import Profile from "../pages/Home/Profile/Index";

import { useMount } from "react-use";

import a11yChecker from "a11y-checker";

import TabContext from "../context/tabContext";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #dadde9",
  },
}));

export default function FixedBottomNavigation() {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const { value, setValue } = React.useContext(TabContext);

  console.log(value);

  useMount(() => {
    a11yChecker();
  });

  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
    if (value === 0) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 10000);
    }
    if (value === 1) {
      setOpen(false);
    }
  }, [value]);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      {value === 0 && <Dashboard />}
      {value === 1 && <Scan />}
      {value === 2 && <Profile />}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction icon={<HomeIcon />} />
          <HtmlTooltip
            open={open}
            onClick={() => console.log("clicked")}
            title={
              <React.Fragment>
                {"Click the button to scan your QR code"}
              </React.Fragment>
            }
            arrow
          >
            <BottomNavigationAction icon={<ScanIcon />} />
          </HtmlTooltip>
          <BottomNavigationAction icon={<ProfileIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
