import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const profileUpdate = createAsyncThunk(
  "admin/profile-update",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.profileDetailsURL,
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const profileUpdateSlice = createSlice({
  name: "profile-update",
  initialState: {
    pupFetching: false,
    pupSuccess: false,
    pupError: false,
    pupErrorMessage: "",
  },
  reducers: {
    clearProfileUpdateState: (state) => {
      state.pupError = false;
      state.pupSuccess = false;
      state.pupFetching = false;

      return state;
    },
  },
  extraReducers: {
    [profileUpdate.fulfilled]: (state, { payload }) => {
      state.profileUpdate = payload?.data?.data;
      state.pupFetching = false;
      state.pupSuccess = true;
      return state;
    },
    [profileUpdate.rejected]: (state, action) => {
      state.pupFetching = false;
      state.pupError = true;
      state.pupErrorMessage = action?.payload;
    },
    [profileUpdate.pending]: (state) => {
      state.pupFetching = true;
    },
  },
});

export const { clearProfileUpdateState } = profileUpdateSlice.actions;

export default profileUpdateSlice.reducer;
