import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";

  const token = localStorage.getItem("helvit-prod-token");

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return new Promise((resolve, reject) => {
    payload.baseURL = process.env.REACT_APP_BASE_URL;

    axios(payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // console.log("success", response);
          resolve(response);
        } else {
          // console.log("failure", response);
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode === undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }

  console.log("intercepter statuscode: ", statusCode);
  return new Promise(() => {
    if (statusCode === 401 && err.config && !err.config.__isRetryRequest) {
      localStorage.removeItem("helvit-prod-token");
      localStorage.removeItem("helvit-prod-user");

      window.location.href = "/auth/login";
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  registerURL: "auth/customer_registration/", // [POST],
  logoutURL: "auth/logout/", // [POST]
  forgotPasswordOtpSendURL: "auth/forgot_password/otp_send/",
  forgotPasswordOtpVerifyURL: "auth/forgot_password/otp_verify/",
  forgotPasswordResetURL: "auth/forgot_password/{id}/reset/",

  ponitsDetailsURL: "customer/points/",
  productImageUploadURL: "customer/qr-code/{id}/images/",
  verifyOtpURL: "customer/image/{id}/verify/",
  regenerateOtpURL: "customer/image/{id}/regenerate_otp/",
  pointsHistoryListURL: "customer/points_history/?limit=1000",
  profileDetailsURL: "customer/profile/",
  scannedCodesListURL: "customer/scanned_qr-codes/?limit=1000",
  redeelPointsURL: "customer/redeem_request/",

  actionHandler,
};
