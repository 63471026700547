import * as React from "react";
import { Grid, Box, AppBar } from "@mui/material";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";

export default function AuthNavbar() {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: "#00435E" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          paddingTop={{ xs: 6, sm: 8, md: 5 }}
          paddingBottom={{ xs: 3, sm: 4, md: 5 }}
        >
          <Logo onClick={() => history.push("/")} />
        </Grid>
      </AppBar>
    </Box>
  );
}
