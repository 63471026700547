import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const uploadProductImage = createAsyncThunk(
  "admin/upload-product-image",
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.productImageUploadURL.replace("{id}", id),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const uploadProductImageSlice = createSlice({
  name: "upload-product-image",
  initialState: {
    imageID: "",
    piuFetching: false,
    piuSuccess: false,
    piuError: false,
    piuErrorMessage: "",
  },
  reducers: {
    clearImageUploadState: (state) => {
      state.piuError = false;
      state.piuSuccess = false;
      state.piuFetching = false;

      return state;
    },
  },
  extraReducers: {
    [uploadProductImage.fulfilled]: (state, { payload }) => {
      state.imageID = "";
      state.imageID = payload?.data?.data?.id;
      localStorage.setItem("imageID", payload?.data?.data?.id);
      console.log(payload?.data?.data?.id);
      state.piuFetching = false;
      state.piuSuccess = true;
      return state;
    },
    [uploadProductImage.rejected]: (state, action) => {
      state.piuFetching = false;
      state.piuError = true;
      state.piuErrorMessage = action?.payload;
    },
    [uploadProductImage.pending]: (state) => {
      state.piuFetching = true;
    },
  },
});

export const { clearImageUploadState } = uploadProductImageSlice.actions;

export default uploadProductImageSlice.reducer;
