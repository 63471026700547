import React, { useState } from "react";
import { InputField } from "../../../components/FormFields";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

export default function BasicDetails(props) {
  const {
    formField: {
      name,
      mobile,
      username,
      email,
      password,
      state,
      district,
      pincode,
      address,
    },
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <React.Fragment>
      <InputField name={name.name} label={name.label} fullWidth type="text" />
      <InputField
        name={mobile.name}
        label={mobile.label}
        type="number"
        fullWidth
      />
      <InputField
        name={username.name}
        label={username.label}
        fullWidth
        type="text"
      />
      <InputField
        name={email.name}
        label={email.label}
        fullWidth
        type="email"
      />
      <InputField
        name={password.name}
        label={password.label}
        fullWidth
        type={showPassword ? "text" : "password"}
        helperText="password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special"
        InputProps={{
          // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <InputField name={address.name} label={address.label} fullWidth />
      <InputField name={state.name} label={state.label} fullWidth />
      <InputField name={district.name} label={district.label} fullWidth />
      <InputField
        name={pincode.name}
        label={pincode.label}
        fullWidth
        type="number"
      />
    </React.Fragment>
  );
}
