import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Grid, Typography, Button } from "@mui/material";
import { Box, styled } from "@mui/system";
import "./otpStyle.css";

import {
  verifyOtp,
  clearVerifyOtpState,
} from "../../../store/slices/verifyOtpSlice";

import {
  regenerateOtp,
  clearRegenerateOtpState,
} from "../../../store/slices/regenerateOtpSlice";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-hot-toast";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "50vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const VerifyOtp = ({ handleOTPSuccess }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [hasErrored, setHasErrored] = useState(false);

  const { votpSuccess, votpError } = useSelector(
    (state) => state.verifyOtpSlice
  );

  const { rgotpSuccess, rgotpError, rgotpErrorMessage } = useSelector(
    (state) => state.regenerateOtpSlice
  );

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp.length === 4) {
      let payload = {
        otp: otp,
      };
      dispatch(
        verifyOtp({ payload, imageID: Number(localStorage.getItem("imageID")) })
      );
    }
  };

  useEffect(() => {
    if (votpSuccess) {
      handleOTPSuccess();
    } else if (votpError) {
      setHasErrored(true);
      dispatch(clearVerifyOtpState());
      setOtp("");
      setTimeout(() => {
        setHasErrored(false);
      }, 3000);
    }
  }, [votpError, votpSuccess]);

  const regenerateOtpFn = (e) => {
    e.preventDefault();
    let payload = {};
    dispatch(
      regenerateOtp({
        payload,
        imageID: Number(localStorage.getItem("imageID")),
      })
    );
  };

  useEffect(() => {
    if (rgotpSuccess) {
      toast.success("OTP Send again successfully");
      dispatch(clearRegenerateOtpState());
    } else if (rgotpError) {
      toast.error("Sorry, something went wrong");
      console.log(rgotpErrorMessage);
      dispatch(clearRegenerateOtpState());
    }
  }, [rgotpSuccess, rgotpError]);

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "center", md: "center" },
          justifyContent: "start",
          marginTop: 10,
        }}
      >
        <Grid item>
          <div className="view">
            <div className="card">
              <form>
                <p>Enter verification code</p>
                <span>Enter the OTP send to your Mobile</span>
                <div
                  className="margin-top--small"
                  style={{ marginTop: "25px" }}
                >
                  <OtpInput
                    inputStyle="inputStyle"
                    numInputs={4}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={handleChange}
                    separator={<span>-</span>}
                    isInputNum
                    shouldAutoFocus
                    value={otp}
                  />
                </div>
              </form>
              <Typography sx={{ mt: 4, fontSize: 13, textAlign: "center" }}>
                Didn't receive OTP?{" "}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={regenerateOtpFn}
                  sx={{
                    fontSize: 13,
                    fontWeight: 600,
                    backgroundColor: "#fff",
                    border: "none",
                    textTransform: "none",
                  }}
                >
                  {" "}
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </JWTRoot>
  );
};

export default VerifyOtp;
