import React, { useEffect } from "react";
import { Container, Grid, TextField, Button, Alert } from "@mui/material";
import { styled } from "@mui/system";
import useStyles from "../../registerStyles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { fetchProfileDetails } from "../../../store/slices/profile/profileDetailsSlice";

import CustomNavbar from "../../../components/CustomNavbar";

import TabContext from "../../../context/tabContext";

import { profileUpdate } from "../../../store/slices/profile/profileUpdateSlice";

const GridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginTop: "6%",
  marginBottom: "10%",
  "@media (max-width: 600px)": {
    marginTop: "30%",
    marginBottom: "20%",
  },
});

const GridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const EditProfile = ({ setEditProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { value, setValue } = React.useContext(TabContext);

  console.log(value);

  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [pincode, setPincode] = React.useState("");

  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("helvit-prod-user"))?.id
  );

  const { profileDetails } = useSelector((state) => state.profileDetailsSlice);

  const { pupSuccess, pupError } = useSelector(
    (state) => state.profileUpdateSlice
  );

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, [user]);

  useEffect(() => {
    setName(profileDetails?.name);
    setMobile(profileDetails?.mobile);
    setEmail(profileDetails?.email);
    setUsername(profileDetails?.username);
    setPassword(profileDetails?.password);
    setAddress(profileDetails?.address);
    setState(profileDetails?.state);
    setDistrict(profileDetails?.district);
    setPincode(profileDetails?.pincode);
  }, [profileDetails]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let payload = {
      id: user,
      name,
      mobile,
      email,
      username,
      password,
      address,
      state,
      district,
      pincode,
    };

    dispatch(profileUpdate({ payload }));
  };

  useEffect(() => {
    if (pupSuccess) {
      setValue(2);
      setEditProfile(false);
    } else if (pupError) {
      setValue(2);
      setEditProfile(false);
    }
  }, [pupSuccess, pupError]);

  return (
    <>
      <CustomNavbar backgroundColor="#00435E" showBackButton={true} logo />

      <Container sx={{ zIndex: 0, width: "100%" }}>
        <GridContainer container spacing={3}>
          {(pupSuccess || pupError) && (
            <GridItem item>
              <Alert
                style={{ width: "100%" }}
                severity={`${pupSuccess ? "success" : pupError ? "error" : ""}`}
              >
                {pupSuccess
                  ? "Profile updated successfully"
                  : pupError
                  ? "Something went wrong"
                  : ""}
              </Alert>
            </GridItem>
          )}
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-name"
              label="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="standard"
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-mobile"
              label="Mobile Number"
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              variant="standard"
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-email"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-password"
              label="Password"
              type="password"
              inputProps={{
                shrink: true,
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-address"
              label="Address"
              variant="standard"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-state"
              label="State"
              value={state}
              variant="standard"
              onChange={(e) => setState(e.target.value)}
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-district"
              label="District"
              variant="standard"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </GridItem>
          <GridItem item>
            <TextField
              sx={{ width: { md: "90%", lg: "30%", xs: "90%" } }}
              id="standard-pin"
              label="Pincode"
              variant="standard"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </GridItem>
          <Grid item xs={12} lg={4}>
            <Button onClick={handleSubmit} className={classes.button}>
              Save Changes
            </Button>
          </Grid>
        </GridContainer>
      </Container>
    </>
  );
};

export default EditProfile;
