import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Chip } from "@mui/material";
import CustomNavbar from "../../../components/CustomNavbar";
import { useSelector } from "react-redux";

import { styled } from "@mui/system";

import redeemed from "../../../assets/redeemed.svg";
import added from "../../../assets/added.svg";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#00435E",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewAll() {
  const { pointsHistoryList } = useSelector(
    (state) => state.pointsHistorySlice
  );

  const { scannedCodesList } = useSelector(
    (state) => state.scannedCodesListSlice
  );

  const [value, setValue] = React.useState(0);
  const [pendingItems, setPendingItems] = React.useState([]);
  const [rejectedItems, setRejectedItems] = React.useState([]);
  const [redeemedAndClaimedItems, setRedeemedAndClaimedItems] = React.useState(
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (scannedCodesList.length > 0) {
      const data = scannedCodesList?.filter(
        (item) => item.status === "pending"
      );
      setPendingItems(data);
    }
    if (scannedCodesList.length > 0) {
      const data = scannedCodesList?.filter(
        (item) => item.status === "rejected"
      );
      setRejectedItems(data);
    }
    if (pointsHistoryList.length > 0) {
      const data = pointsHistoryList?.filter(
        (item) => item.status === "redeemed" || item.status === "claimed"
      );
      setRedeemedAndClaimedItems(data);
    }
  }, [scannedCodesList, pointsHistoryList]);

  return (
    <>
      <CustomNavbar
        backgroundColor="#00435E"
        showBackButton={true}
        text="All Activities"
      />
      <Box sx={{ pb: 4, mt: 14 }}>
        <StyledTabs
          variant="fullWidth"
          textColor="inherit"
          indicatorColor="#00435E"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Statement" {...a11yProps(1)} />
          <Tab label="Rejected" {...a11yProps(2)} />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          {pendingItems.length > 0 &&
            pendingItems?.map(({ qr_id, date }, index) => (
              <ListItem button key={index}>
                <ListItemAvatar>
                  <Avatar alt="Profile Picture" src={added} />
                </ListItemAvatar>
                <ListItemText primary={`${qr_id.split("-")[0] || "--"}`} />
                <Typography component={"div"} sx={{ color: "green" }}>
                  {date?.slice(0, 10)}
                </Typography>
              </ListItem>
            ))}
          {pendingItems.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <lottie-player
                src="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {redeemedAndClaimedItems.length > 0 &&
            redeemedAndClaimedItems?.map(
              ({ date_redeemed, status, point }, index) => (
                <ListItem button key={index}>
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={added} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${date_redeemed?.slice(0, 10) || "--"}`}
                    secondary={status}
                  />
                  <Typography
                    component={"div"}
                    sx={{ color: status === "redeemed" ? "red" : "green" }}
                  >
                    {status === "redeemed" ? "- " : "+ "} {point}
                  </Typography>
                </ListItem>
              )
            )}
          {redeemedAndClaimedItems.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <lottie-player
                src="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <List>
            {rejectedItems.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  src="https://assets3.lottiefiles.com/packages/lf20_ydo1amjm.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "300px", height: "300px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            )}
            {rejectedItems.length > 0 &&
              rejectedItems?.map(
                ({ date, rejection_reason, rejection_note }, index) => (
                  <ListItem button key={index}>
                    <ListItemAvatar>
                      <Avatar alt="Profile Picture" src={redeemed} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        rejection_reason === "other"
                          ? rejection_note
                          : rejection_reason || "--"
                      }
                    />
                    <Typography component={"div"} color={"red"}>
                      {date?.slice(0, 10)}
                    </Typography>
                  </ListItem>
                )
              )}
          </List>
        </TabPanel>
      </Box>
    </>
  );
}
