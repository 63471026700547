import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import failure from "../../../assets/failure.svg";
import { Button } from "@material-ui/core";
import useStyles from "../../registerStyles";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const ResetFailed = ({ backToLogin }) => {
  const classes = useStyles();

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "center", md: "center" },
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Grid item>
          <img src={failure} alt="failure" />
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, fontSize: 22 }}
            component="h3"
          >
            Error...
          </Typography>
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 400, fontSize: 22 }}
            component="h3"
          >
            Sorry Something Went Wrong
          </Typography>
        </Grid>
        <Grid
          item
          textAlign="center"
          sx={{ mt: 2, width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" } }}
        >
          <Button onClick={(e) => backToLogin(e)} className={classes.button}>
            Go Back
          </Button>
        </Grid>
      </Grid>
    </JWTRoot>
  );
};

export default ResetFailed;
