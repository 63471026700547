import React from "react";
import { Typography } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";
import pending from "../../assets/pending.svg";
import whatsapp from "../../assets/whatsapp.png";
import { Button } from "@material-ui/core";
import useStyles from "../registerStyles";
import { useHistory } from "react-router-dom";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
}));

const RegisterDone = () => {
  const classes = useStyles();
  const history = useHistory();

  const num = "+919072669102";
  const name = "Helvit";
  const message = `Hi ${name}, My registration is pending. Please help me out.`;

  const openWhatsapp = () => {
    window.open(`https://wa.me/${num}?text=${message}`, "_blank");
  };

  const logout = () => {
    localStorage.clear();
    history.push("/auth/login");
  };

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "center", md: "center" },
          justifyContent: "center",
          marginTop: 12,
        }}
      >
        <Grid item>
          <img src={pending} alt="pending" />
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, fontSize: 22 }}
            component="h3"
          >
            Pending
          </Typography>
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 400, fontSize: 22 }}
            component="h3"
          >
            Waiting for admin approval
          </Typography>
        </Grid>
        <Grid
          item
          textAlign="center"
          sx={{ mt: 2, width: { md: "100%", xs: "80%", sm: "80%" } }}
        >
          <Button onClick={openWhatsapp} className={classes.button}>
            <img src={whatsapp} alt="whatsapp" style={{ marginRight: "5px" }} />
            Contact Support
          </Button>
          <Button onClick={logout} className={classes.secondaryButton}>
            Logout
          </Button>
        </Grid>
      </Grid>
    </JWTRoot>
  );
};

export default RegisterDone;
