import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("helvit-prod-token")) {
        return (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        );
      } else if (
        isAuthProtected &&
        localStorage.getItem("helvit-prod-token") &&
        !localStorage.getItem("verified")
      ) {
        return (
          <Redirect
            to={{
              pathname: "/auth/verification-pending",
              state: { from: props.location },
            }}
          />
        );
      }

      return <Component exact {...props} />;
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
};

export default Authmiddleware;
