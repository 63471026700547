import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(3),
    width: "100%",
    borderRadius: "28px",
    backgroundColor: "#01435F",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#01435F",
    },
  },
  secondaryButton: {
    marginTop: theme.spacing(3),
    width: "100%",
    borderRadius: "28px",
    backgroundColor: "#fff",
    color: "#01435F",
    textTransform: "none",
    border: "1px solid #01435F",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  backbutton: {
    marginTop: theme.spacing(3),
    width: "100%",
    borderRadius: "28px",
    border: "1px solid #01435F",
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    position: "absolute",
    marginTop: "14px",
    marginRight: "10px",
    top: "50%",
    left: "47%",
  },
  secondaryText: {
    color: "#01435F",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Roboto",
  },
  profilepic: {
    cursor: "pointer",
    width: "100%",
    position: "relative",
    // "&:hover": {
    //   "& $avatar": {
    //     opacity: "0.3",
    //     border: "2px solid #01435F",
    //   },
    //   "& $avatarText": {
    //     position: "absolute",
    //     display: "block",
    //     left: "50%",
    //     bottom: "50%",
    //     transform: "translate(-50%, -50%)",
    //     color: "#111",
    //     padding: "5px",
    //     borderRadius: "5px",
    //     backgroundColor: "#fff",
    //     fontSize: "10px",
    //     fontWeight: "500",
    //   },
    // },
  },

  avatar: {
    height: "150px",
    width: "150px",
    borderRadius: "50%",
  },
  avatarText: {
    display: "none",
  },
}));
