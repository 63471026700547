import { createSlice } from "@reduxjs/toolkit";

const qrcodeResultSlice = createSlice({
  name: "qr-result",
  initialState: {
    qrResult: "",
  },
  reducers: {
    updateQrResult: (state, action) => {
      console.log(action);
      state.qrResult = action.payload;
      return state;
    },
    clearQrResult: (state) => {
      state.qrResult = "";
      return state;
    },
  },
});

export const { updateQrResult, clearQrResult } = qrcodeResultSlice.actions;

export default qrcodeResultSlice.reducer;
