import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Grid, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qrcode from "../../../assets/qr-code.png";
import useStyles from "../../registerStyles";

import Html5QrcodePlugin from "./QR.jsx";

import { useDispatch } from "react-redux";

import { updateQrResult } from "../../../store/slices/qrcodeResultSlice";

import DummyCarousel from "./DummyImages";

import QrSuccess from "./QrSuccess";
import InvalidQR from "./InvalidQr";
import AlreadyClaimed from "./AlreadyClaimed";
import ImageUpload from "./ImageUpload";
import ImageUploadSuccess from "./UploadSuccess";
import ImageUploadFailed from "./UploadFailed";
import VerifyOtp from "./VerifyOtp";
import PointSuccess from "./PointSuccess";

const Qrscan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const previewStyle = { height: "100%", width: "100%" };

  const [status, setStatus] = useState(false);
  const [sample, setSample] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleError = (err) => {
    console.err(err);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setStatus(true);
  };

  useEffect(() => {
    let qrID = localStorage.getItem("qrID");
    if (qrID) {
      validateQR(qrID);
    }
  }, []);

  let baseURL = process.env.REACT_APP_BASE_URL;

  function validateQR(value) {
    console.log(value);
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}customer/validate_fields/`, {
          username: null,
          mobile: null,
          qr_id: value,
        })
        .then(() => {
          dispatch(updateQrResult(value));
          handleQRScanSuccess();
          resolve(true);
        })
        .catch((error) => {
          if (error.response.data.message === "This code is already claimed") {
            handleQRAlreadyClaimed();
          } else if (
            error.response.data.message === "Unauthorized: Access is denied."
          ) {
            handleBackToLogin();
          } else {
            handleQRInvalid();
          }
          reject(false);
        });
    });
  }

  const handleScan = (res, result) => {
    if (res) {
      let url = new URL(res);
      let id = url.pathname.split("/")[2];
      localStorage.setItem("qrID", id);
      validateQR(id);
    }
  };

  const handleBackToLogin = () => {
    localStorage.clear();
    history.push("/auth/login");
  };

  const handleBackToHome = () => {
    localStorage.removeItem("qrID");
    history.push("/");
  };

  const handleQRScanSuccess = () => {
    setCurrentStep(1);
  };

  const handleQRSuccessNext = (event) => {
    event.preventDefault();
    setCurrentStep(4);
  };

  const handleQRAlreadyClaimed = () => {
    setCurrentStep(2);
  };

  const handleQRInvalid = () => {
    setCurrentStep(3);
  };

  const handleUploadSuccess = () => {
    setCurrentStep(5);
  };

  const handleUploadFail = () => {
    setCurrentStep(6);
  };

  const handleBackToUpload = () => {
    setCurrentStep(4);
  };

  const handleUploadSuccessNext = () => {
    setCurrentStep(7);
  };

  const handleOTPSuccess = () => {
    setCurrentStep(8);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        {
          0: (
            <>
              {!status && !sample && (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      position: "absolute",
                      top: "25%",
                    }}
                  >
                    <img
                      src={qrcode}
                      alt="qr-code"
                      height="200px"
                      width="200px"
                    />
                  </Grid>
                  <Grid
                    item
                    textAlign="center"
                    sx={{
                      mt: 2,
                      width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" },
                      position: "absolute",
                      bottom: "15%",
                    }}
                  >
                    <Button
                      onClick={() => setSample(true)}
                      className={classes.secondaryButton}
                    >
                      Sample Images
                    </Button>
                    <Button
                      onClick={(e) => handleClick(e)}
                      className={classes.button}
                    >
                      Scan Now
                    </Button>
                  </Grid>
                </Grid>
              )}

              {sample && (
                <DummyCarousel
                  classes={classes}
                  setStatus={setStatus}
                  setSample={setSample}
                />
              )}

              {status && !sample && (
                <Grid container>
                  <Grid item xs={12}>
                    {/* <QrReader
                      delay={300}
                      style={previewStyle}
                      onError={handleError}
                      onScan={handleScan}
                    /> */}
                    {/* <BarcodeScannerComponent
                      style={previewStyle}
                      onUpdate={(err, result) => {
                        if (result) handleScan(result);
                      }}
                    /> */}
                    <Html5QrcodePlugin
                      fps={10}
                      qrbox={250}
                      disableFlip={false}
                      qrCodeSuccessCallback={handleScan}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      position: "absolute",
                      bottom: "20%",
                      textAlign: "center",
                    }}
                  ></Grid>
                </Grid>
              )}
            </>
          ),
          1: <QrSuccess handleQRSuccessNext={handleQRSuccessNext} />,
          2: <AlreadyClaimed handleBackToHome={handleBackToHome} />,
          3: <InvalidQR handleBackToHome={handleBackToHome} />,
          4: (
            <ImageUpload
              handleBackToHome={handleBackToHome}
              handleUploadSuccess={handleUploadSuccess}
              handleUploadFail={handleUploadFail}
            />
          ),
          5: (
            <ImageUploadSuccess
              handleUploadSuccessNext={handleUploadSuccessNext}
            />
          ),
          6: <ImageUploadFailed handleBackToUpload={handleBackToUpload} />,
          7: <VerifyOtp handleOTPSuccess={handleOTPSuccess} />,
          8: <PointSuccess handleBackToHome={handleBackToHome} />,
        }[currentStep]
      }
    </Box>
  );
};

export default Qrscan;
