import { configureStore } from "@reduxjs/toolkit";

import loginSlice from "./slices/auth/loginSlice";
import registerSlice from "./slices/auth/registerSlice";
import forgotPasswordOtpSendSlice from "./slices/auth/forgetPasswordOtpSendSlice";
import forgotPasswordOtpVerifySlice from "./slices/auth/forgotPasswordOtpVerifySlice";
import forgotPasswordResetSlice from "./slices/auth/forgotPasswordResetSlice";

import pointsDetailsSlice from "./slices/pointsDetailsSlice";
import qrcodeResultSlice from "./slices/qrcodeResultSlice";
import uploadProductImageSlice from "./slices/productImageUploadSlice";
import verifyOtpSlice from "./slices/verifyOtpSlice";
import regenerateOtpSlice from "./slices/regenerateOtpSlice";
import pointsHistorySlice from "./slices/pointsHistorySlice";
import profileDetailsSlice from "./slices/profile/profileDetailsSlice";
import profileUpdateSlice from "./slices/profile/profileUpdateSlice";
import scannedCodesListSlice from "./slices/scannedCodesListSlice";
import validateFieldsSlice from "./slices/validationSlice";
import redeemPointsSlice from "./slices/redeemPointsSlice";

export default configureStore({
  reducer: {
    loginSlice,
    registerSlice,
    pointsDetailsSlice,
    qrcodeResultSlice,
    uploadProductImageSlice,
    verifyOtpSlice,
    regenerateOtpSlice,
    pointsHistorySlice,
    profileDetailsSlice,
    profileUpdateSlice,
    scannedCodesListSlice,
    validateFieldsSlice,
    forgotPasswordOtpSendSlice,
    forgotPasswordOtpVerifySlice,
    forgotPasswordResetSlice,
    redeemPointsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
