export default {
  formId: "registerForm",
  formField: {
    name: {
      name: "name",
      label: "Name",
      requiredErrorMsg: "Name is required",
    },
    mobile: {
      name: "mobile",
      label: "Mobile",
      requiredErrorMsg: "Mobile is required",
    },
    username: {
      name: "username",
      label: "Username",
      requiredErrorMsg: "Username is required",
      invalidErrorMsg: "Username must not contain spaces or special charcters",
    },
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Email is required",
      invalidErrorMsg: "Email is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      requiredErrorMsg: "Password is required",
      invalidErrorMsg: "Password must be at least 4 characters long",
    },
    state: {
      name: "state",
      label: "State",
      requiredErrorMsg: "State is required",
    },
    district: {
      name: "district",
      label: "District",
      requiredErrorMsg: "District is required",
    },
    pincode: {
      name: "pincode",
      label: "Pincode",
      requiredErrorMsg: "Pincode is required",
    },
    address: {
      name: "address",
      label: "Address",
      requiredErrorMsg: "Address is required",
    },
    dealer_name: {
      name: "dealer_name",
      label: "Dealer Name",
      requiredErrorMsg: "Dealer Name is required",
    },
    dealer_mobile: {
      name: "dealer_mobile",
      label: "Dealer Mobile",
      requiredErrorMsg: "Dealer Mobile is required",
    },
    dealer_address: {
      name: "dealer_address",
      label: "Dealer Address",
      requiredErrorMsg: "Dealer Address is required",
    },
    dealer_state: {
      name: "dealer_state",
      label: "Dealer State",
      requiredErrorMsg: "Dealer State is required",
    },

    dealer_district: {
      name: "dealer_district",
      label: "Dealer District",
      requiredErrorMsg: "Dealer District is required",
    },
    dealer_pincode: {
      name: "dealer_pincode",
      label: "Dealer Pincode",
      requiredErrorMsg: "Dealer Pincode is required",
    },
  },
};
