import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";

import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";

import BasicDetails from "../RegisterForms/Forms/BasicDetails";
import DealerDetails from "../RegisterForms/Forms/DealerDetails";
import RegisterDone from "../RegisterForms/RegisterDone";

import validationSchema from "../RegisterForms/FormModel/validationSchema";
import registerFormModel from "../RegisterForms/FormModel/registerFormModel";
import formInitialValues from "../RegisterForms/FormModel/formInitialValues";

import useStyles from "../registerStyles";

import {
  registerCustomer,
  clearRegisterState,
} from "../../store/slices/auth/registerSlice";

import { toast } from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

const steps = ["Basic Details", "Dealer Details"];
const { formId, formField } = registerFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <BasicDetails formField={formField} />;
    case 1:
      return <DealerDetails formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  width: "100% !important",
  minHeight: "100% !important",
}));

export default function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const {
    registerSuccess,
    registerError,
    registerErrorMessage,
    registerFetching,
  } = useSelector((state) => state.registerSlice);

  async function _submitForm(values, actions) {
    console.log(values);
    dispatch(registerCustomer({ payload: values }));
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  useEffect(() => {
    if (registerSuccess) {
      setActiveStep(activeStep + 1);
      dispatch(clearRegisterState());
    } else if (registerError) {
      toast.error(registerErrorMessage);
      dispatch(clearRegisterState());
    }
  }, [registerSuccess, registerError]);

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "center", md: "center" },
          justifyContent: "center",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        {activeStep < steps.length && (
          <Grid
            p={4}
            height="100%"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "start", sm: "center", md: "center" },
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, fontSize: 28 }}
              component="h3"
            >
              {activeStep === 0 ? "Sign Up" : "Dealer Details"}
            </Typography>
          </Grid>
        )}
        <Grid
          sx={{
            width: { xs: "100%", md: "25%" },
            paddingX: 4,
          }}
        >
          {activeStep === steps.length ? (
            <RegisterDone />
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              validateOnChange={true}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {_renderStepContent(activeStep)}

                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={_handleBack}
                        className={classes.backbutton}
                      >
                        Back
                      </Button>
                    )}
                    <div className={classes.wrapper}>
                      {!registerFetching && (
                        <Button
                          disabled={registerFetching}
                          type="submit"
                          variant="contained"
                          className={classes.button}
                        >
                          {isLastStep ? "Sign Up" : "Next"}
                        </Button>
                      )}
                      {registerFetching && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Grid>
      </Grid>
    </JWTRoot>
  );
}
