import * as Yup from "yup";
import registerFormModel from "./registerFormModel";
import axios from "axios";

const {
  formField: {
    name,
    mobile,
    username,
    password,
    state,
    district,
    pincode,
    address,
    dealer_name,
    dealer_mobile,
    dealer_address,
    dealer_state,
    dealer_district,
    dealer_pincode,
  },
} = registerFormModel;

let baseURL = process.env.REACT_APP_BASE_URL;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [mobile.name]: Yup.string()
      .required(`${mobile.requiredErrorMsg}`)
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits")
      .test(
        "Unique Mobile",
        "Mobile number already in use", // <- key, message
        function (value) {
          return new Promise((resolve, reject) => {
            axios
              .post(`${baseURL}customer/validate_fields/`, {
                username: null,
                mobile: value,
                qr_id: null,
              })
              .then((res) => {
                console.log(res);
                resolve(true);
              })
              .catch((error) => {
                console.log(error);
                resolve(false);
              });
          });
        }
      ),
    [username.name]: Yup.string()
      .required(`${username.requiredErrorMsg}`)
      .test(
        "Unique Username",
        "Username already in use", // <- key, message
        function (value) {
          return new Promise((resolve, reject) => {
            axios
              .post(`${baseURL}customer/validate_fields/`, {
                username: value,
                mobile: null,
                qr_id: null,
              })
              .then((res) => {
                console.log(res);
                resolve(true);
              })
              .catch((error) => {
                console.log(error);
                resolve(false);
              });
          });
        }
      )
      .test(
        "isValidUsername",
        "username must not contain spaces or special charcters",
        (value, context) => {
          const hasSymbole = /^(\d|\w)+$/.test(value);
          let validConditions = 0;
          const conditions = [!hasSymbole];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions > 0) {
            return false;
          }
          return true;
        }
      ),

    [password.name]: Yup.string()
      .required(`${password.requiredErrorMsg}`)
      .min(
        6,
        "password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special"
      )
      .test(
        "isValidPass",
        "password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special",
        (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSymbole = /[!@#%&]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 3;
          const conditions = [
            hasLowerCase,
            hasUpperCase,
            hasNumber,
            hasSymbole,
          ];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }
      ),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [district.name]: Yup.string().required(`${district.requiredErrorMsg}`),
    [pincode.name]: Yup.string()
      .nullable()
      .required(`${pincode.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [dealer_name.name]: Yup.string().required(
      `${dealer_name.requiredErrorMsg}`
    ),
    [dealer_mobile.name]: Yup.string()
      .required(`${dealer_mobile.requiredErrorMsg}`)
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits"),
    [dealer_address.name]: Yup.string().required(
      `${dealer_address.requiredErrorMsg}`
    ),
    [dealer_state.name]: Yup.string().required(
      `${dealer_state.requiredErrorMsg}`
    ),
    [dealer_district.name]: Yup.string().required(
      `${dealer_district.requiredErrorMsg}`
    ),
    [dealer_pincode.name]: Yup.string()
      .nullable()
      .required(`${dealer_pincode.requiredErrorMsg}`),
  }),
];
