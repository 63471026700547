import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const loginUser = createAsyncThunk(
  "admin/login",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.loginURL,
        method: "POST",
        data: {
          username,
          password,
        },
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        if (sam.data.user.user_type !== "customer") {
          return rejectWithValue("You are not a customer");
        }
        localStorage.setItem("helvit-prod-token", sam.data.token.access);
        localStorage.setItem("helvit-prod-user", JSON.stringify(sam.data.user));
        if (sam.data.user.is_verified) {
          localStorage.setItem("verified", "true");
        }
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginFetching: false,
    loginSuccess: false,
    loginError: false,
    loginErrorMessage: "",
  },
  reducers: {
    clearLoginState: (state) => {
      state.loginError = false;
      state.loginSuccess = false;
      state.loginFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loginFetching = false;
      state.loginSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, action) => {
      state.loginFetching = false;
      state.loginError = true;
      state.loginErrorMessage = action?.payload;
    },
    [loginUser.pending]: (state) => {
      state.loginFetching = true;
    },
  },
});

export const { clearLoginState } = loginSlice.actions;

export default loginSlice.reducer;
