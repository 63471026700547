import React from "react";
import { Typography, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import useStyles from "../../registerStyles";
import { LoadingButton } from "@mui/lab";
import person1 from "../../../assets/forgotpass/person1.svg";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  marginTop: 90,
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const ResetPassword = ({
  password,
  confirmPassword,
  passwordLoading,
  handlePasswordSubmit,
  handlePasswordChange,
  handleConfirmPasswordChange,
  passwordError,
  confirmPasswordError,
}) => {
  const classes = useStyles();

  return (
    <>
      <form onSubmit={handlePasswordSubmit}>
        <JWTRoot>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "center", md: "center" },
              justifyContent: "center",
              marginTop: 4,
            }}
          >
            <Grid item>
              <img src={person1} alt="qrsuccess" />
            </Grid>
            <Grid
              item
              width={{ xs: "80%", sm: "80%", md: "20%" }}
              sx={{
                mt: 2,
              }}
            >
              <Typography
                variant="h4"
                textAlign={{ xs: "start", sm: "center", md: "center" }}
                sx={{ fontWeight: 600, fontSize: 22 }}
                component="h3"
              >
                Set new Password
              </Typography>
            </Grid>
            <Grid
              item
              textAlign="start"
              sx={{ mt: 2 }}
              width={{ xs: "80%", sm: "90%", md: "20%" }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 14 }} component="h3">
                Enter your new password below .
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ mt: 4 }}
              width={{ xs: "80%", sm: "80%", md: "20%" }}
            >
              <TextField
                error={passwordError.status}
                label="Password"
                type="text"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                helperText={
                  passwordError.status ? passwordError.helperText : ""
                }
              />
            </Grid>
            <Grid
              item
              sx={{ mt: 4 }}
              width={{ xs: "80%", sm: "80%", md: "20%" }}
            >
              <TextField
                error={confirmPasswordError.status}
                label="Confirm Password"
                type="text"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                fullWidth
                helperText={
                  confirmPasswordError.status
                    ? confirmPasswordError.helperText
                    : ""
                }
              />
            </Grid>
            <Grid
              item
              textAlign="center"
              sx={{
                mt: 1,
                width: { lg: "20%", md: "30%", xs: "80%", sm: "50%" },
              }}
            >
              <LoadingButton
                loading={passwordLoading}
                variant="contained"
                type="submit"
                className={classes.button}
                sx={{
                  my: 2,
                  width: "100%",
                  backgroundColor: "#00435E",
                  borderRadius: 28,
                  color: "#fff",
                  "& 	.MuiLoadingButton-loadingIndicator": {
                    color: "#fff",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </JWTRoot>
      </form>
    </>
  );
};

export default ResetPassword;
