import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Alert,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useHistory, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loginUser, clearLoginState } from "../../store/slices/auth/loginSlice";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
}));

// inital login credentials
const initialValues = {
  username: "",
  password: "",
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Password must be 4 character length")
    .required("Password is required!"),
  username: Yup.string().required("Username is required!"),
});

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { qrID } = useParams();
  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    if (qrID) {
      localStorage.setItem("qrID", qrID);
      let auth = localStorage.getItem("helvit-prod-token");
      if (auth) {
        history.push("/admin/index");
      }
    }
  }, [qrID]);

  const { loginSuccess, loginError, loginErrorMessage, loginFetching } =
    useSelector((state) => state.loginSlice);

  const handleFormSubmit = (values) => {
    try {
      let { username, password } = values;
      dispatch(loginUser({ username, password }));
    } catch (e) {
      toast.error("Login Failed");
    }
  };

  useEffect(() => {
    if (loginSuccess) {
      history.push("/admin/index");
      dispatch(clearLoginState());
    }
  }, [loginSuccess]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearLoginState());
    }, 5000);
  }, [loginError]);

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "start", sm: "center", md: "center" },
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Grid
          p={4}
          height="100%"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "start", sm: "center", md: "center" },
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, fontSize: 28 }}
            component="h3"
          >
            Sign In
          </Typography>
        </Grid>
        {loginError && (
          <Grid
            sx={{
              width: { xs: "100%", md: "25%" },
              paddingX: 4,
              marginBottom: 4,
              transition: "all 0.3s ease-in-out",
            }}
          >
            <Alert sx={{ transition: "all 0.3s ease-in-out" }} severity="error">
              {loginErrorMessage}
            </Alert>
          </Grid>
        )}
        <Grid
          sx={{
            width: { xs: "100%", md: "25%" },
            paddingX: 4,
          }}
        >
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="username"
                  label="Username"
                  variant="standard"
                  onBlur={handleBlur}
                  value={values.username}
                  onChange={handleChange}
                  helperText={touched.username && errors.username}
                  error={Boolean(errors.username && touched.username)}
                  sx={{ mb: 3 }}
                />
                {!showPassword && (
                  <TextField
                    fullWidth
                    size="small"
                    name="password"
                    type="password"
                    label="Password"
                    variant="standard"
                    onBlur={handleBlur}
                    value={values.password}
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                    sx={{ mb: 1.5 }}
                  />
                )}
                {showPassword && (
                  <TextField
                    fullWidth
                    size="small"
                    name="password"
                    type="text"
                    label="Password"
                    variant="standard"
                    onBlur={handleBlur}
                    value={values.password}
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                    sx={{ mb: 1.5 }}
                  />
                )}
                <Grid container>
                  <Grid item xs={6}>
                    <FormGroup sx={{ width: "100%" }}>
                      <FormControlLabel
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.875rem",
                            whiteSpace: "nowrap",
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            checked={showPassword}
                            onChange={() => setShowPassword(!showPassword)}
                          />
                        }
                        label="Show Password"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <NavLink
                      to="/auth/forgot-password"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "right",
                          color: "#00435E",
                          height: "100%",
                        }}
                      >
                        Forgot Password?
                      </Typography>
                    </NavLink>
                  </Grid>
                </Grid>
                <FlexBox justifyContent="space-between">
                  <LoadingButton
                    type="submit"
                    loading={loginFetching}
                    variant="contained"
                    sx={{
                      my: 2,
                      width: "100%",
                      backgroundColor: "#00435E",
                      borderRadius: 28,
                      color: "#fff",
                    }}
                  >
                    Sign In
                  </LoadingButton>
                </FlexBox>
                <JustifyBox>
                  <Typography sx={{ textAlign: "center" }}>
                    New to Helvit ?
                    <br />
                    <NavLink
                      to="/auth/register"
                      style={{ textDecoration: "none" }}
                    >
                      Click here to create an account
                    </NavLink>
                  </Typography>
                </JustifyBox>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </JWTRoot>
  );
};

export default Login;
