import React from "react";
import { Grid, Card, Typography, Icon } from "@mui/material";

const PointCard = ({ background, textColor, up, text, pointsDetails }) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: background,
        borderRadius: "8px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        padding: "10px 15px",
        marginTop: "32px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Icon style={{ color: textColor, fontSize: "30px" }}>
            {up ? "arrow_circle_up" : "arrow_circle_down"}
          </Icon>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "30px" }}>
          <Typography
            variant={
              Number(pointsDetails?.redeemed_point) > 100000 ||
              Number(pointsDetails?.total_point) > 100000
                ? "h4"
                : "h3"
            }
            sx={{
              fontWeight: 400,
              textAlign: "start",
              color: textColor,
              fontFamily: "Roboto",
            }}
          >
            {up ? pointsDetails?.redeemed_point : pointsDetails?.total_point}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "30px" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              textAlign: "start",
              color: textColor,
              fontFamily: "Roboto",
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PointCard;
