import React from "react";
import { Grid, Typography } from "@mui/material";

const Balance = ({ pointsDetails }) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: "32px" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 400,
            textAlign: "center",
            color: "#00435E",
            fontFamily: "Roboto",
          }}
        >
          {pointsDetails?.active_point}{" "}
          <span style={{ fontSize: "12px", fontWeight: "500" }}>Balance</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Balance;
