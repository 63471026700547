import React from "react";
import { InputField } from "../../../components/FormFields";

export default function DealerDetails(props) {
  const {
    formField: {
      dealer_name,
      dealer_mobile,
      dealer_address,
      dealer_state,
      dealer_district,
      dealer_pincode,
    },
  } = props;

  return (
    <React.Fragment>
      <InputField
        name={dealer_name.name}
        label={dealer_name.label}
        fullWidth
        type="text"
      />

      <InputField
        name={dealer_mobile.name}
        label={dealer_mobile.label}
        fullWidth
        type="tel"
      />

      <InputField
        name={dealer_address.name}
        label={dealer_address.label}
        fullWidth
        type="text"
      />

      <InputField
        name={dealer_state.name}
        label={dealer_state.label}
        fullWidth
        type="text"
      />

      <InputField
        name={dealer_district.name}
        label={dealer_district.label}
        fullWidth
        type="text"
      />

      <InputField
        name={dealer_pincode.name}
        label={dealer_pincode.label}
        fullWidth
        type="number"
      />
    </React.Fragment>
  );
}
