import * as React from "react";
import { Grid, Box } from "@mui/material";
import ColoredLogo from "./ColoredLogo";
import { useHistory } from "react-router-dom";

const AdminNavbar = () => {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        paddingTop={{ xs: 6, sm: 8, md: 5 }}
        paddingBottom={{ xs: 3, sm: 4, md: 5 }}
      >
        <ColoredLogo onClick={() => history.push("/")} />
      </Grid>
      {/* </AppBar> */}
    </Box>
  );
};

export default AdminNavbar;
