import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchProfileDetails = createAsyncThunk(
  "admin/profile-details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.profileDetailsURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const profileDetailsSlice = createSlice({
  name: "profile-details",
  initialState: {
    profileDetails: null,
    fpdFetching: false,
    fpdSuccess: false,
    fpdError: false,
    fpdErrorMessage: "",
  },
  reducers: {
    clearProfileDetailsState: (state) => {
      state.fpdError = false;
      state.fpdSuccess = false;
      state.fpdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchProfileDetails.fulfilled]: (state, { payload }) => {
      state.profileDetails = {};
      state.profileDetails = payload?.data?.data;
      state.fpdFetching = false;
      state.fpdSuccess = true;
      return state;
    },
    [fetchProfileDetails.rejected]: (state, action) => {
      state.fpdFetching = false;
      state.fpdError = true;
      state.fpdErrorMessage = action?.payload;
    },
    [fetchProfileDetails.pending]: (state) => {
      state.fpdFetching = true;
    },
  },
});

export const { clearProfileDetailsState } = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
