import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import AdminNavbar from "../components/AdminNavbar";
import CustomNavbar from "../components/CustomNavbar";

import { Grid } from "@mui/material";

import routes from "../routes/allRoutes";

import BottomNavbar from "../components/BottomNavbar";

import TabContext from "../context/tabContext";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState("");
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <TabContext.Provider value={{ value, setValue }}>
          {currentPath === "index" && value !== 2 && <AdminNavbar {...props} />}
          {currentPath === "verify-otp" && (
            <CustomNavbar
              backgroundColor="#00435E"
              showBackButton={true}
              logo
            />
          )}
          {currentPath === "index" && value == 1 && (
            <CustomNavbar
              backgroundColor="#00435E"
              showBackButton={true}
              resetTab={setValue}
              hardReset={true}
            />
          )}
          {currentPath === "index" && value == 2 && (
            <CustomNavbar
              backgroundColor="#00435E"
              showBackButton={true}
              resetTab={setValue}
              text={"Personal Details"}
            />
          )}
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          {currentPath === "index" && (
            <Grid>
              <BottomNavbar value={value} setValue={setValue} />
            </Grid>
          )}
        </TabContext.Provider>
      </div>
    </>
  );
};

export default Admin;
