import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchScannedCodes = createAsyncThunk(
  "admin/scanned-codes-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.scannedCodesListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const scannedCodesListSlice = createSlice({
  name: "scanned-codes-list",
  initialState: {
    scannedCodesList: {},
    sclFetching: false,
    sclSuccess: false,
    sclError: false,
    sclErrorMessage: "",
  },
  reducers: {
    clearScannedCodesState: (state) => {
      state.sclError = false;
      state.sclSuccess = false;
      state.sclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchScannedCodes.fulfilled]: (state, { payload }) => {
      console.log(typeof payload);
      state.scannedCodesList = [];
      state.scannedCodesList = [...payload?.data?.data?.results];
      state.sclFetching = false;
      state.sclSuccess = true;
      return state;
    },
    [fetchScannedCodes.rejected]: (state, action) => {
      state.sclFetching = false;
      state.sclError = true;
      state.sclErrorMessage = action?.payload;
    },
    [fetchScannedCodes.pending]: (state) => {
      state.sclFetching = true;
    },
  },
});

export const { clearScannedCodesState } = scannedCodesListSlice.actions;

export default scannedCodesListSlice.reducer;
