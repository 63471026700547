import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const forgotPasswordReset = createAsyncThunk(
  "admin/forgot-password-reset",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.forgotPasswordResetURL.replace("{id}", payload.userID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const forgotPasswordResetSlice = createSlice({
  name: "forgot-password-reset",
  initialState: {
    mobile: "",
    fprFetching: false,
    fprSuccess: false,
    fprError: false,
    fprErrorMessage: "",
  },
  reducers: {
    clearFprState: (state) => {
      state.fprError = false;
      state.fprSuccess = false;
      state.fprFetching = false;

      return state;
    },
  },
  extraReducers: {
    [forgotPasswordReset.fulfilled]: (state, { payload }) => {
      console.log("otp verify payload", payload);
      state.fprFetching = false;
      state.fprSuccess = true;
      return state;
    },
    [forgotPasswordReset.rejected]: (state, action) => {
      state.fprFetching = false;
      state.fprError = true;
      state.fprErrorMessage = action?.payload;
    },
    [forgotPasswordReset.pending]: (state) => {
      state.fprFetching = true;
    },
  },
});

export const { clearFprState } = forgotPasswordResetSlice.actions;

export default forgotPasswordResetSlice.reducer;
