import React from "react";
import { Typography, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import useStyles from "../../registerStyles";
import { LoadingButton } from "@mui/lab";
import person2 from "../../../assets/forgotpass/person2.svg";
import OtpInput from "react-otp-input";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  marginTop: 90,
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const EnterOtp = ({
  otp,
  otpLoading,
  handleOtpSubmit,
  otpError,
  handleOtpChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleOtpSubmit}>
        <JWTRoot>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "center", md: "center" },
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Grid item>
              <img src={person2} alt="qrsuccess" />
            </Grid>
            <Grid
              item
              width={{ xs: "80%", sm: "80%", md: "20%" }}
              sx={{
                mt: 2,
              }}
            >
              <Typography
                variant="h4"
                textAlign={{ xs: "start", sm: "center", md: "center" }}
                sx={{ fontWeight: 600, fontSize: 22 }}
                component="h3"
              >
                OTP Verification
              </Typography>
            </Grid>
            <Grid
              item
              textAlign={{ xs: "start", sm: "center", md: "center" }}
              sx={{ mt: 2 }}
              width={{ xs: "80%", sm: "80%", md: "20%" }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 14 }} component="h3">
                Enter the OTP sent to +91 xxxxxxxxxx
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ mt: 4 }}
              width={{ xs: "90%", sm: "80%", md: "20%" }}
            >
              <OtpInput
                inputStyle="inputStyle"
                numInputs={4}
                hasErrored={otpError}
                errorStyle="error"
                onChange={handleOtpChange}
                separator={<span>-</span>}
                isInputNum
                shouldAutoFocus
                value={otp}
              />
            </Grid>
            <Grid
              item
              textAlign="center"
              sx={{
                mt: 1,
                width: { lg: "20%", md: "30%", xs: "80%", sm: "60%" },
              }}
            >
              <LoadingButton
                loading={otpLoading}
                type="submit"
                variant="contained"
                className={classes.button}
                sx={{
                  my: 2,
                  width: "100%",
                  backgroundColor: "#00435E",
                  borderRadius: 28,
                  color: "#fff",
                  "& 	.MuiLoadingButton-loadingIndicator": {
                    color: "#fff",
                  },
                }}
              >
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </JWTRoot>
      </form>
    </>
  );
};

export default EnterOtp;
