import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import { useEffect } from "react";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {
    showTorchButtonIfSupported: true,
    showFlipCameraButtonIfSupported: true,
  };
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const Html5QrcodePlugin = (props) => {
  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    const html5QrCode = new Html5Qrcode(qrcodeRegionId);

    html5QrCode.start(
      { facingMode: "environment" },
      config,
      props.qrCodeSuccessCallback
    );

    // const html5QrcodeScanner = new Html5QrcodeScanner(
    //   qrcodeRegionId,
    //   config,
    //   verbose
    // );
    // html5QrcodeScanner.render(
    //   props.qrCodeSuccessCallback,
    //   props.qrCodeErrorCallback
    // );

    // cleanup function when component will unmount
    return () => {
      html5QrCode.stop();
      //   html5QrcodeScanner.clear().catch((error) => {
      //     console.error("Failed to clear html5QrcodeScanner. ", error);
      //   });
    };
  }, []);

  return <div id={qrcodeRegionId} />;
};

export default Html5QrcodePlugin;
