import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const regenerateOtp = createAsyncThunk(
  "admin/regenerate-otp",
  async ({ payload, imageID }, { rejectWithValue }) => {
    console.log(imageID);
    try {
      const response = await api.actionHandler({
        url: api.regenerateOtpURL.replace("{id}", imageID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const regenerateOtpSlice = createSlice({
  name: "regenerate-otp",
  initialState: {
    rgotpFetching: false,
    rgotpSuccess: false,
    rgotpError: false,
    rgotpErrorMessage: "",
  },
  reducers: {
    clearRegenerateOtpState: (state) => {
      state.rgotpError = false;
      state.rgotpSuccess = false;
      state.rgotpFetching = false;

      return state;
    },
  },
  extraReducers: {
    [regenerateOtp.fulfilled]: (state, { payload }) => {
      state.rgotpFetching = false;
      state.rgotpSuccess = true;
      return state;
    },
    [regenerateOtp.rejected]: (state, action) => {
      state.rgotpFetching = false;
      state.rgotpError = true;
      state.rgotpErrorMessage = action?.payload;
    },
    [regenerateOtp.pending]: (state) => {
      state.rgotpFetching = true;
    },
  },
});

export const { clearRegenerateOtpState } = regenerateOtpSlice.actions;

export default regenerateOtpSlice.reducer;
