import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const validateFields = createAsyncThunk(
  "admin/validate-fields",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.validateFieldsURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const validateFieldsSlice = createSlice({
  name: "validate-fields",
  initialState: {
    validateFetching: false,
    validateSuccess: false,
    validateError: false,
    validateErrorMessage: "",
  },
  reducers: {
    clearValidateFields: (state) => {
      state.validateError = false;
      state.validateSuccess = false;
      state.validateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [validateFields.fulfilled]: (state, { payload }) => {
      state.validateFetching = false;
      state.validateSuccess = true;
      return state;
    },
    [validateFields.rejected]: (state, action) => {
      state.validateFetching = false;
      state.validateError = true;
      state.validateErrorMessage = action?.payload;
    },
    [validateFields.pending]: (state) => {
      state.validateFetching = true;
    },
  },
});

export const { clearValidateFields } = validateFieldsSlice.actions;

export default validateFieldsSlice.reducer;
