import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import Grid from "@mui/material/Grid";

import AuthNavbar from "../components/AuthNavbar";
import CustomNavbar from "../components/CustomNavbar";

import routes from "../routes/allRoutes";

const Auth = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  console.log();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div ref={mainContent}>
        {location.pathname.split("/")[2] === "register" ? (
          <CustomNavbar backgroundColor="#00435E" showBackButton={true} />
        ) : location.pathname.split("/")[2] === "login" ? (
          <AuthNavbar />
        ) : (
          ""
        )}
        <Grid>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Grid>
      </div>
    </>
  );
};

export default Auth;
