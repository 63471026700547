import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import Authmiddleware from "./routes/middleware/Authmiddleware";

import { Toaster } from "react-hot-toast";

import { Provider } from "react-redux";
import store from "./store/Index";

import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Authmiddleware
              component={AuthLayout}
              isAuthProtected={false}
              path="/auth"
            />
            <Authmiddleware
              component={AdminLayout}
              isAuthProtected={true}
              path="/admin"
            />
            <Redirect from="/" to="/admin/index" />
          </Switch>
          <Toaster position="top-center" reverseOrder={false} />
          <CssBaseline />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
