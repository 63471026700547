import registerFormModel from "./registerFormModel";
const {
  formField: {
    name,
    mobile,
    username,
    email,
    password,
    state,
    district,
    pincode,
    address,
    dealer_name,
    dealer_mobile,
    dealer_address,
    dealer_state,
    dealer_district,
    dealer_pincode,
  },
} = registerFormModel;

export default {
  [name.name]: "",
  [mobile.name]: "",
  [username.name]: "",
  [email.name]: "",
  [password.name]: "",
  [state.name]: "",
  [district.name]: "",
  [pincode.name]: null,
  [address.name]: "",
  [dealer_name.name]: "",
  [dealer_mobile.name]: "",
  [dealer_address.name]: "",
  [dealer_state.name]: "",
  [dealer_district.name]: "",
  [dealer_pincode.name]: null,
};
