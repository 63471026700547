import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const verifyOtp = createAsyncThunk(
  "admin/verify-otp",
  async ({ payload, imageID }, { rejectWithValue }) => {
    console.log(imageID);
    try {
      const response = await api.actionHandler({
        url: api.verifyOtpURL.replace("{id}", imageID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const verifyOtpSlice = createSlice({
  name: "verify-otp",
  initialState: {
    votpFetching: false,
    votpSuccess: false,
    votpError: false,
    votpErrorMessage: "",
  },
  reducers: {
    clearVerifyOtpState: (state) => {
      state.votpError = false;
      state.votpSuccess = false;
      state.votpFetching = false;

      return state;
    },
  },
  extraReducers: {
    [verifyOtp.fulfilled]: (state, { payload }) => {
      state.votpFetching = false;
      state.votpSuccess = true;
      return state;
    },
    [verifyOtp.rejected]: (state, action) => {
      state.votpFetching = false;
      state.votpError = true;
      state.votpErrorMessage = action?.payload;
    },
    [verifyOtp.pending]: (state) => {
      state.votpFetching = true;
    },
  },
});

export const { clearVerifyOtpState } = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;
