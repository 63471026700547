import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Grid } from "@mui/material";
import first from "../../../assets/dummy/1.png";
import second from "../../../assets/dummy/2.png";
import third from "../../../assets/dummy/3.png";

function DummyCarousel({ classes, setStatus, setSample }) {
  var items = [
    {
      name: "Random Image #1",
      description: "Probably the most random thing you have ever seen!",
      image: first,
    },
    {
      name: "Random Image #2",
      description: "Hello World!",
      image: second,
    },
    {
      name: "Random Image #3",
      description: "Hola World!",
      image: third,
    },
  ];

  const handleClick = (e) => {
    e.preventDefault();
    setSample(false);
    setStatus(true);
  };

  return (
    <>
      <Carousel
        sx={{
          width: "100%",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {items.map((item, i) => (
          <img src={item.image} alt="first" height="500px" width="100%" />
        ))}
      </Carousel>
      <Grid
        item
        textAlign="center"
        sx={{
          width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" },
          position: "absolute",
          bottom: "9%",
        }}
      >
        <Button onClick={handleClick} className={classes.button}>
          Scan Now
        </Button>
      </Grid>
    </>
  );
}
export default DummyCarousel;
