import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useStyles from "../../registerStyles";
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Grid,
  InputAdornment,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  redeemPoints,
  clearRedpState,
} from "../../../store/slices/redeemPointsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "100%",
  maxWidth: "500px",
  borderRadius: "15px",
};

export default function RedeemModal({ toggle, setToggle }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => setToggle(false);

  const [points, setPoints] = React.useState(1000);
  const [pointError, setPointError] = React.useState({
    status: false,
    message: "",
  });

  const { redpFetching, redpSuccess, redpError, redpErrorMessage } =
    useSelector((state) => state.redeemPointsSlice);

  const handleRedeem = () => {
    if (points < 1000) {
      setPointError({
        status: true,
        message: "Minimum 1000 points required",
      });
      setTimeout(() => {
        setPointError({
          status: false,
          message: "",
        });
        dispatch(clearRedpState());
      }, 3000);
      return;
    } else {
      setPointError({
        status: false,
        message: "",
      });
      let payload = {
        point: points,
        notes: "",
      };
      dispatch(redeemPoints({ payload }));
    }
  };

  React.useEffect(() => {
    if (redpSuccess) {
      setToggle(false);
    } else if (redpError) {
      setPointError({
        status: true,
        message: redpErrorMessage,
      });
      setTimeout(() => {
        setPointError({
          status: false,
          message: "",
        });
        dispatch(clearRedpState());
      }, 3000);
    }
  }, [redpSuccess, redpError]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={toggle}
      onClose={handleClose}
      sx={{ marginInline: { xs: 5 } }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={toggle}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Redeem Points
          </Typography>
          <FormControl sx={{ mt: 5, mb: 3, width: "100%" }} variant="filled">
            <OutlinedInput
              id="filled-adornment-weight"
              value={points}
              fullWidth
              type="number"
              onChange={(e) => setPoints(e.target.value)}
              endAdornment={
                <InputAdornment position="end">Points</InputAdornment>
              }
              aria-describedby="filled-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              error={points < 1000 || pointError.status}
            />
            <FormHelperText id="filled-weight-helper-text">
              {pointError.status
                ? pointError.message
                : points < 1000
                ? "Minimum 1000 points required"
                : ""}
            </FormHelperText>
          </FormControl>
          <Grid item xs={12} lg={5} md={4}>
            <Button onClick={handleRedeem} className={classes.button}>
              Redeem
            </Button>
          </Grid>
          <Grid item xs={12} lg={5} md={4}>
            <Button onClick={handleClose} className={classes.secondaryButton}>
              Cancel
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
