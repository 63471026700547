import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Button } from "@material-ui/core";
import useStyles from "../../registerStyles";
import uploadimage from "../../../assets/uploadimage.svg";

import { useHistory } from "react-router-dom";

import S3 from "react-aws-s3";
import { Buffer } from "buffer";

import { useDispatch, useSelector } from "react-redux";

import {
  uploadProductImage,
  clearImageUploadState,
} from "../../../store/slices/productImageUploadSlice";

// @ts-ignore
window.Buffer = Buffer;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "70vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const ImageUpload = ({
  handleBackToHome,
  handleUploadSuccess,
  handleUploadFail,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const qrID = localStorage.getItem("qrID");

  const [image, setImage] = useState(null);

  const [uploading, setUploading] = useState(false);

  const { piuSuccess, piuError, piuErrorMessage } = useSelector(
    (state) => state.uploadProductImageSlice
  );

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_BUCKET_REGION,
    dirName: 'helvit-india',
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  useEffect(() => {
    async function uploadImage() {
      if (!qrID) {
        handleBackToHome();
      } else if (image) {
        setUploading(true);
        await ReactS3Client.uploadFile(image, image.name.replace(/\s/g, ""))
          .then((data) => {
            let payload = {
              image: data.location,
            };
            dispatch(
              uploadProductImage({
                payload,
                id: qrID,
              })
            );
          })
          .catch((err) => {
            console.log(err);
            alert(err);
            setUploading(false);
            handleUploadFail();
          });
      }
    }
    uploadImage();
  }, [image]);

  useEffect(() => {
    if (piuSuccess) {
      setUploading(false);
      handleUploadSuccess();
      dispatch(clearImageUploadState());
    } else if (piuError) {
      setUploading(false);
      if (
        piuErrorMessage ===
        "You have already uploaded image, verify otp to send"
      ) {
        history.push("/admin/image-upload-success");
        dispatch(clearImageUploadState());
      } else if (piuErrorMessage === "This code is already claimed") {
        history.push("/admin/already-claimed");
        dispatch(clearImageUploadState());
        localStorage.removeItem("qrID");
      } else {
        dispatch(clearImageUploadState());
        history.push("/admin/image-upload-failed");
      }
    }
  }, [piuSuccess, piuError]);

  return (
    <JWTRoot>
      {!uploading && (
        <Grid
          container
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center", md: "center" },
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <Grid item>
            <img src={uploadimage} alt="uploadimage" />
          </Grid>
          <Grid item textAlign="center" sx={{ mt: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, fontSize: 22 }}
              component="h3"
            >
              Upload the Product Image
            </Typography>
          </Grid>
          <Grid
            item
            textAlign="center"
            sx={{
              mt: 2,
              width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" },
            }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="icon-button-file-one"
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <label htmlFor="icon-button-file-one" style={{ width: "100%" }}>
              <Button
                aria-label="upload picture"
                className={classes.button}
                component="span"
              >
                Upload from Gallery
              </Button>
            </label>
          </Grid>
          <Grid item textAlign="center" sx={{ mt: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 400, fontSize: 22 }}
              component="h3"
            >
              or
            </Typography>
          </Grid>
          <Grid
            item
            textAlign="center"
            sx={{ width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" } }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="icon-button-file-two"
              type="file"
              capture="environment"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <label htmlFor="icon-button-file-two" style={{ width: "100%" }}>
              <Button
                aria-label="upload picture"
                className={classes.secondaryButton}
                component="span"
              >
                Open Camera
              </Button>
            </label>
          </Grid>
        </Grid>
      )}
      {uploading && (
        <lottie-player
          src="https://assets6.lottiefiles.com/packages/lf20_GxMZME.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px" }}
          loop
          autoplay
        ></lottie-player>
      )}
    </JWTRoot>
  );
};

export default ImageUpload;
