import React, { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Balance from "./BalanceItem";

import axios from "axios";

import PointCard from "./PointCard";
import PointHistory from "./PointHistory";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchPointsDetails } from "../../../store/slices/pointsDetailsSlice";
import { fetchPointsHistory } from "../../../store/slices/pointsHistorySlice";
import { fetchScannedCodes } from "../../../store/slices/scannedCodesListSlice";

import TabContext from "../../../context/tabContext";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { value, setValue } = React.useContext(TabContext);

  const { pointsDetails } = useSelector((state) => state.pointsDetailsSlice);

  const { pointsHistoryList } = useSelector(
    (state) => state.pointsHistorySlice
  );

  const { scannedCodesList } = useSelector(
    (state) => state.scannedCodesListSlice
  );

  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  useEffect(() => {
    dispatch(fetchPointsDetails());
    dispatch(fetchPointsHistory());
    dispatch(fetchScannedCodes());
  }, []);

  useEffect(() => {
    let qrID = localStorage.getItem("qrID");
    if (qrID) {
      validateQR(qrID);
    }
  }, []);

  let baseURL = process.env.REACT_APP_BASE_URL;

  function validateQR(value) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}customer/validate_fields/`, {
          username: null,
          mobile: null,
          qr_id: value,
        })
        .then(() => {
          setValue(1);
          resolve(true);
        })
        .catch((error) => {
          if (error.response.data.message === "This code is already claimed") {
            history.push("/admin/already-claimed");
          } else if (
            error.response.data.message === "Unauthorized: Access is denied."
          ) {
            localStorage.clear();
            history.push("/auth/login");
          } else {
            alert(JSON.stringify(error.response.data.message));
            history.push("/admin/invalid-qr");
          }
          reject(false);
        });
    });
  }

  return (
    <Container>
      <Balance pointsDetails={pointsDetails} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <PointCard
            pointsDetails={pointsDetails}
            background="#00435E"
            textColor="#fff"
            text="Point Added"
          />
        </Grid>
        <Grid item xs={6}>
          <PointCard
            pointsDetails={pointsDetails}
            background="rgba(0, 67, 94, .1)"
            textColor="#00435E"
            opacity
            up
            text="Point Redeemed"
          />
        </Grid>
      </Grid>
      <Grid>
        <Typography
          variant="h4"
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            marginLeft: "15px",
            color: "#00435E",
          }}
        >
          Point History
        </Typography>
      </Grid>
      <PointHistory
        pointsHistoryList={pointsHistoryList}
        scannedCodesList={scannedCodesList}
      />
    </Container>
  );
};

export default Dashboard;
