import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import success from "../../../assets/success.svg";
import { Button } from "@material-ui/core";
import useStyles from "../../registerStyles";

import { useDispatch } from "react-redux";

import { clearVerifyOtpState } from "../../../store/slices/verifyOtpSlice";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#fff",
  minHeight: "100% !important",
  height: "70vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const PointSuccess = ({ handleBackToHome }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearVerifyOtpState());
    localStorage.removeItem("imageID");
    localStorage.removeItem("qrID");
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    handleBackToHome();
  };

  return (
    <JWTRoot>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "center", md: "center" },
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Grid item>
          <img src={success} alt="qrsuccess" />
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, fontSize: 22 }}
            component="h3"
          >
            Congrats!
          </Typography>
        </Grid>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, fontSize: 22 }}
            component="h3"
          >
            Points Submitted Successfully
          </Typography>
        </Grid>
        <Grid
          item
          textAlign="center"
          sx={{ mt: 2, width: { lg: "20%", md: "100%", xs: "80%", sm: "80%" } }}
        >
          <Button onClick={(e) => handleClick(e)} className={classes.button}>
            Go to Home
          </Button>
        </Grid>
      </Grid>
    </JWTRoot>
  );
};

export default PointSuccess;
