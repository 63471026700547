import React from "react";
import { useHistory } from "react-router-dom";
import CustomNavbar from "../../../components/CustomNavbar";

import { useDispatch, useSelector } from "react-redux";

import EnterMobile from "./EnterMobile";
import EnterOtp from "./EnterOtp";
import ResetPassword from "./ResetPassword";
import ResetSuccess from "./ResetSuccess";
import ResetFailed from "./ResetFailed";

import {
  forgotPasswordOtpSend,
  clearFposState,
} from "../../../store/slices/auth/forgetPasswordOtpSendSlice";
import {
  forgotPasswordOtpVerify,
  clearFpovState,
} from "../../../store/slices/auth/forgotPasswordOtpVerifySlice";
import {
  forgotPasswordReset,
  clearFprState,
} from "../../../store/slices/auth/forgotPasswordResetSlice";

const Index = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { fposFetching, fposSuccess, fposError, fposErrorMessage } =
    useSelector((state) => state.forgotPasswordOtpSendSlice);

  const { userID, fpovFetching, fpovSuccess, fpovError } = useSelector(
    (state) => state.forgotPasswordOtpVerifySlice
  );

  const { fprFetching, fprSuccess, fprError } = useSelector(
    (state) => state.forgotPasswordResetSlice
  );

  const [currentStep, setCurrentStep] = React.useState(0);
  const [mobileError, setMobileError] = React.useState({
    status: false,
    helperText: "",
  });
  const [otpError, setOtpError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState({
    status: false,
    helperText: "",
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState({
    status: false,
    helperText: "",
  });

  const [mobile, setMobile] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
    if (event.target.value.length === 10) {
      setMobileError({ status: false, helperText: "" });
    } else {
      setMobileError({
        status: true,
        helperText: "Please enter a valid mobile number",
      });
    }
  };

  const handleMobileSubmit = (event) => {
    event.preventDefault();
    if (mobile.length === 10) {
      setMobileError({ status: false, helperText: "" });
      let payload = {
        mobile: mobile,
      };
      dispatch(forgotPasswordOtpSend({ payload }));
    } else {
      setMobileError({
        status: true,
        helperText: "Please enter a valid mobile number",
      });
    }
  };

  React.useEffect(() => {
    if (fposError) {
      setMobileError({ status: true, helperText: fposErrorMessage });
      setTimeout(() => {
        setMobileError({ status: false, helperText: "" });
        dispatch(clearFposState());
      }, 3000);
    }
    if (fposSuccess) {
      dispatch(clearFposState());
      setCurrentStep(1);
    }
  }, [fposError, fposSuccess]);

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    dispatch(
      forgotPasswordOtpVerify({ payload: { mobile: mobile, otp: otp } })
    );
  };

  React.useEffect(() => {
    if (fpovError) {
      setOtpError(true);
      setTimeout(() => {
        setOtpError(false);
        dispatch(clearFpovState());
      }, 3000);
    }
    if (fpovSuccess) {
      dispatch(clearFpovState());
      setCurrentStep(2);
    }
  }, [fpovError, fpovSuccess]);

  const testPassword = (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbole = /[!@#%&]/.test(value);
    const has6characters = value.length >= 6;
    let validConditions = 0;
    const numberOfMustBeValidConditions = 3;
    const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
    conditions.forEach((condition) => (condition ? validConditions++ : null));
    if (validConditions >= numberOfMustBeValidConditions && has6characters) {
      return true;
    }
    return false;
  };

  const testConfirmPassword = (value) => {
    if (value === password) {
      return true;
    }
    return false;
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (testPassword(event.target.value)) {
      setPasswordError({ status: false, helperText: "" });
    } else {
      setPasswordError({
        status: true,
        helperText:
          "password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special",
      });
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (testConfirmPassword(event.target.value)) {
      setConfirmPasswordError({ status: false, helperText: "" });
    } else {
      setConfirmPasswordError({
        status: true,
        helperText: "Password does not match",
      });
    }
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    if (testPassword(password)) {
      setPasswordError({ status: false, helperText: "" });
      if (testConfirmPassword(confirmPassword)) {
        setConfirmPasswordError({ status: false, helperText: "" });
        dispatch(
          forgotPasswordReset({
            payload: { password: password, userID: userID },
          })
        );
      } else {
        setConfirmPasswordError({
          status: true,
          helperText: "Password does not match",
        });
      }
    } else {
      setPasswordError({
        status: true,
        helperText:
          "password must contain 6 or more characters with at least one of each: uppercase, lowercase, number and special",
      });
    }
  };

  React.useEffect(() => {
    if (fprError) {
      dispatch(clearFprState());
      setCurrentStep(4);
    }
    if (fprSuccess) {
      dispatch(clearFprState());
      setCurrentStep(3);
    }
  }, [fprError, fprSuccess]);

  const backToLogin = (e) => {
    e.preventDefault();
    history.push("/auth/login");
  };

  return (
    <>
      <CustomNavbar backgroundColor="#00435E" showBackButton={true} logo />
      {
        {
          0: (
            <EnterMobile
              mobileError={mobileError}
              mobile={mobile}
              mobileLoading={fposFetching}
              handleMobileChange={handleMobileChange}
              handleMobileSubmit={handleMobileSubmit}
            />
          ),
          1: (
            <EnterOtp
              otpError={otpError}
              otp={otp}
              otpLoading={fpovFetching}
              handleOtpSubmit={handleOtpSubmit}
              handleOtpChange={handleOtpChange}
            />
          ),
          2: (
            <ResetPassword
              password={password}
              confirmPassword={confirmPassword}
              passwordLoading={fprFetching}
              handlePasswordSubmit={handlePasswordSubmit}
              handlePasswordChange={handlePasswordChange}
              handleConfirmPasswordChange={handleConfirmPasswordChange}
              passwordError={passwordError}
              confirmPasswordError={confirmPasswordError}
            />
          ),
          3: <ResetSuccess backToLogin={backToLogin} />,
          4: <ResetFailed backToLogin={backToLogin} />,
        }[currentStep]
      }
    </>
  );
};

export default Index;
