import * as React from "react";
import { Grid, Box, AppBar, Typography } from "@mui/material";
import Logo from "./Logo";
import leftArrow from "../assets/left-arrow.svg";
import { useHistory } from "react-router-dom";

export default function CustomNavbar({
  backgroundColor,
  showBackButton,
  logo,
  text,
  resetTab,
  hardReset,
}) {
  const history = useHistory();
  const handleBackButton = (e) => {
    e.preventDefault();
    if (hardReset) {
      resetTab(0);
      localStorage.removeItem("qrID");
      localStorage.removeItem("imageID");
      history.push("/");
    }
    if (resetTab) {
      resetTab(0);
    } else {
      history.push("/");
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1,
      }}
    >
      <AppBar position="static" style={{ backgroundColor: backgroundColor }}>
        <Grid
          container
          flexDirection="row"
          paddingTop={{ xs: 6, sm: 8, md: 3 }}
          paddingBottom={{ xs: 3, sm: 4, md: 3 }}
          paddingX={{ xs: 2, sm: 4, md: 5 }}
        >
          {showBackButton && (
            <Grid md={5} xs={4} item>
              <img
                style={{ cursor: "pointer" }}
                role="button"
                onClick={handleBackButton}
                src={leftArrow}
                alt="left-arrow"
              />
            </Grid>
          )}
          <Grid item md={6} xs={8} sx={{ marginLeft: { md: 5 } }}>
            {logo && <Logo />}
            {text && (
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                {text}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}
